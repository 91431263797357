import React from "react";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";
import { Tuerschloss } from "./PriceCalculator/elements/Constants";

const Tuerschloesser = ({ city, business }) => {
    let citySlug = typeof city !== "undefined" ? city.slug : "";
    return (
        <div className="section brandmeldeanlagen">
            <h2 className="has-text-centered">
                Die Vorteile mit unseren elektronischen{" "}
                {business ? "Schließanlagen" : "Türschlössern"}
            </h2>
            <ul className="checkmark centered-content">
                <li>
                    <span className="top">Mehr Freiheit ohne Schlüssel</span>
                    <p>
                        Unsere Schließanlagen funktionieren vollständig
                        elektronisch und können somit ganz ohne Schlüssel
                        betrieben werden. Zugefallene Türen und teure
                        Schlüsseldienste gibt es nicht mehr.
                    </p>
                </li>
                <li>
                    <span className="top">
                        Mehr Kontrolle dank smarter Zutrittskontrolle
                    </span>
                    <p>
                        Verwalten Sie alle Zutrittsrechte übersichtlich dank
                        einfach bedienbarer Software. Online oder per App.
                        Jederzeit. Weltweit. Damit behalten Sie den Durchblick.
                    </p>
                </li>
                <li>
                    <span className="top">Keine hohen Kosten bei Verlust</span>
                    <p>
                        Im Falle des Verlusts oder Diebstahls des Chips, kann
                        dieser sofort online gesperrt werden. Ein Zugang ist
                        damit nicht mehr möglich. Das spart hohe Kosten und Sie
                        sind immer auf der sicheren Seite.
                    </p>
                </li>
                <li>
                    <span className="top">
                        Bis zu 1.600€ sparen dank KfW Förderung
                    </span>
                    <p>
                        Mit der staatlichen Förderung der KfW können Sie bis zu
                        20% sparen, denn elektronische Türschlösser werden mit
                        bis zu 1.600€ vom Staat bezuschusst. Wir zeigen Ihnen,
                        wie das geht.
                    </p>
                </li>
            </ul>
            <div className="has-text-centered">
                <PriceCalculationButton
                    id="btn_ vorteilesystcat_mehrerfahren"
                    text="Mehr erfahren"
                    city={citySlug}
                    product={Tuerschloss}
                />
                <br />
            </div>
        </div>
    );
};

export default Tuerschloesser;
