import ReactSimplyCarousel from "react-simply-carousel";

import React, { useState } from "react";
const TickBlue = "/img/homepage/icons8-done-30.png";

const listValues = [
    {
        title: "Aufschaltung auf unsere 24/7 Notrufleitstelle",
        listArray: [
            "Im Alarmfall wird automatische unsere 24/7 Notrufleitstelle informiert",
            "Die Spezialisten der Notrufleitstelle leiten innerhalb weniger Sekunden die notwendigen Schritte ein (z.B. Alarmierung der Polizei) ",
            "Erweiterung: Sicherheitsdienst inkl. Schlüsselverwahrung, welcher im Alarmfall ebenfalls rausgeschickt werden kann",
            "Erweiterung: Alarmverifizierung durch Live-Bilder, wobei die Notrufleitstelle im Alarmfall auf Live-Bilder zugreifen kann",
        ],
    },
    {
        title: "SIM-Karte für redundante Alarmierung via Handynetz",
        listArray: [
            "Durch diesen Service ist sichergestellt, dass Sie bzw. die MeinAlarm24 Notrufleitstelle im Alarmfall auch bei einem Ausfall der Internetverbindung sofort benachrichtigt werden.",
            "Sollte die Internetverbindung nicht zur Verfügung stehen (z.B. aufgrund eines Stromausfalls, einer technischen Störung oder wegen Sabotage durch Einbrecher), wechselt die Alarmanlage automatisch auf das Handynetz. ",
        ],
    },
    {
        title: `Lebenslange Hardware-Garantie`,
        style: { paddingTop: "20px" },
        listArray: [
            "Für einen Zeitraum von 15 Jahren nach der Installation erhalten Sie eine Garantie auf alle Komponenten Ihrer Anlage",
            "Im Defekt- bzw. Schadensfall ersetzen oder reparieren wir die technischen Komponenten",
            "Die Funktionsfähigkeit Ihrer Anlage bleibt somit in unserer Verantwortung und ist zu jedem Zeitpunkt sichergestellt ",
        ],
    },
    {
        title: "Rundum-Sorglos-Paket inkl. Wartungsvertrag",
        listArray: [
            "Wartungen durch unsere zertifizierten Techniker",
            "Im Zuge der Wartung erfolgt u.a. eine Funktionsprüfung aller Komponenten inkl. Firmware Updates, ein vollständiger Systemtest, sowie der Batteriewechsel",
            "Premium-Telefon-Support an 365 Tagen im Jahr",
            "Priorisierte Terminvergabe",
        ],
    },
];
function ColumnItem({ title, listArray, style }) {
    let width;
    let height = "32rem";
    const items = listArray.map((item) => <ListItem text={item} />);
    if (typeof window !== "undefined") {
        console.log("screen.width:::::::::::::;;;;", window.screen.width);
        width = window.screen.width > 580 ? "28rem" : "20rem";
        if (window.screen.width < 580) {
            // const aero1 = document.getElementById("aeros-custom1");
            // aero1.style.display = "none";
            setTimeout(() => {
                document.getElementById("aeros-custom2").style.display = "none";
                document.getElementById("aeros-custom1").style.display = "none";
            }, 1000);
            width = "24rem";
        }
        if (window.screen.width < 450) {
            // width = "18rem";
            width = "20rem";
            // height = "44rem";
            height = "35rem";
        }
    }
    return (
        <div
            className="column has-text-centered "
            style={{ width: width, height: height }}
        >
            <div
                className="card box-shaddow-box "
                style={{
                    // border: "4px solid black",
                    backgroundColor: "#F2F2F2",
                }}
            >
                <div
                    className="card-title"
                    style={{
                        paddingTop: "18px",
                        paddingBottom: "18px",
                        fontSize: "22px",
                        fontWeight: "600",
                        padding: "15px",
                    }}
                >
                    {title}
                </div>
                <div
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        textAlign: "initial",
                        marginLeft: "15px",
                        paddingRight: "15px",
                    }}
                >
                    <div style={style}>{items}</div>
                </div>
            </div>
        </div>
    );
}
function ListItem(props) {
    return (
        <p style={{ marginTop: "16px" }}>
            <img
                src={TickBlue}
                style={{
                    width: "35px",
                    height: "35px",
                    verticalAlign: "middle",
                    display: "block",
                    float: "left",
                }}
            />
            <span
                style={{
                    position: "relative",
                    // top: "-7px",
                    verticalAlign: "middle",
                    left: "5px",

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    // webkitLineClamp: "3",
                    webkitBoxOrient: "vertical",
                    marginRight: "2px",
                }}
                className="font-pb2"
            >
                {/* <b> {props.text}</b> */}
                {props.text}
            </span>
        </p>
    );
}
const listlog = listValues.map((item) => {
    return (
        <ColumnItem
            title={item.title}
            listArray={item.listArray}
            style={item.style}
        />
    );
});

const Slider = ({ product }) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    if (typeof window !== "undefined") {
        console.log("screen.width:::::::::::::;;;;", window.screen.width);
        if (window.screen.width < 580) {
            setTimeout(() => {
                console.log(
                    "fireeeeeeeeeeeeeeed",
                    document.getElementById("aeros-custom2")
                );
                document.getElementById("aeros-custom2").style.display = "none";
                document.getElementById("aeros-custom1").style.display = "none";
            }, 1000);
        }
    }
    return (
        <div>
            <div className="section how-to2" style={{ marginTop: "40px" }}>
                <div className="columns">
                    <div className="column has-text-centered">
                        <h2 className="title">
                            Entdecken Sie unser umfassendes Angebot
                            langfristiger Sicherheitsservices.
                        </h2>
                        <h3>
                            Und steigern Sie damit die Sicherheit Ihrer
                            Alarmanlage.
                        </h3>
                    </div>
                </div>
                <div>
                    <ReactSimplyCarousel
                        activeSlideIndex={activeSlideIndex}
                        onRequestChange={setActiveSlideIndex}
                        itemsToShow={1}
                        itemsToScroll={1}
                        forwardBtnProps={{
                            //here you can also pass className, or any other button element attributes
                            id: "aeros-custom1",

                            style: {
                                alignSelf: "center",
                                background: "white",
                                border: "none",
                                // borderRadius: "50%",
                                color: "grey",
                                cursor: "pointer",
                                fontSize: "20px",
                                height: 30,
                                lineHeight: 1,
                                textAlign: "center",
                                width: 30,
                                display: "block",
                            },
                            children: (
                                <span>
                                    <i className=" fas is-size-2 fa-arrow-circle-right " />
                                </span>
                            ),
                        }}
                        backwardBtnProps={{
                            //here you can also pass className, or any other button element attributes
                            id: "aeros-custom2",
                            style: {
                                alignSelf: "center",
                                background: "white",
                                border: "none",
                                // borderRadius: "50%",
                                color: "grey",
                                cursor: "pointer",
                                fontSize: "20px",
                                height: 30,
                                lineHeight: 1,
                                textAlign: "center",
                                width: 50,
                                display: "block",
                            },
                            children: (
                                <span>
                                    <i className="fas is-size-2 fa-arrow-circle-left" />
                                </span>
                            ),
                        }}
                        responsiveProps={[
                            {
                                itemsToShow: 2,
                                itemsToScroll: 2,
                                // minWidth: 768,
                                minWidth: 960,
                            },
                        ]}
                        dotsNav={{
                            show: true,
                            itemBtnProps: {
                                style: {
                                    height: 16,
                                    width: 16,
                                    borderRadius: "50%",
                                    border: 0,
                                },
                            },
                            activeItemBtnProps: {
                                style: {
                                    height: 16,
                                    width: 16,
                                    borderRadius: "50%",
                                    border: 0,
                                    background: "black",
                                },
                            },
                        }}
                        speed={400}
                        easing="linear"
                    >
                        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

                        {listValues.map((slide, i) => (
                            <p>
                                <ColumnItem
                                    title={slide.title}
                                    listArray={slide.listArray}
                                    style={slide.style}
                                />
                            </p>
                        ))}
                    </ReactSimplyCarousel>
                </div>
            </div>
        </div>
    );
};

export default Slider;
