import React from "react";
import { Link } from "gatsby";

export default class Products_page_new_Comp1 extends React.Component {
    render() {
        return (
            <div style={{ backgroundColor: "#F2F2F2" }}>
                <div
                    className="section how-to2"
                    style={{
                        marginTop: "40px",
                        paddingLeft: "30px",
                        paddingRight: "10px",
                    }}
                >
                    <div className="body">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2 className="title">
                                    Ihre Vorteile mit unseren Alarmanlagen
                                </h2>
                            </div>
                        </div>
                        <div>
                            <div className="text-block-product">
                                <h3>
                                    Smarte Alarmanlagen – intuitiv bedienbar
                                </h3>
                                <p>
                                    Unsere Alarmanlagen sind einfach und
                                    intuitiv bedienbar - auch per Smartphone.
                                    Außerdem können sie mit einer Vielzahl von
                                    Smart-Home Komponenten in Haus, Wohnung oder
                                    Büro erweitert werden.
                                </p>
                            </div>

                            <div className="text-block-product">
                                <h3>
                                    Alarmierung unserer Notrufleitstelle und
                                    unseres Wachdienstes
                                </h3>
                                <p>
                                    Auf Wunsch können unsere Alarmanlagen auf
                                    unsere 24/7-Notrufleitstelle aufgeschaltet
                                    werden. Diese kann im Ernstfall sowohl die
                                    Polizei als auch unseren Wachdienst
                                    alarmieren, so dass eine Partei binnen
                                    Minuten bei Ihnen ist. Das ermöglicht einen
                                    besonders zuverlässigen Einbruchschutz Ihres
                                    Sicherheitssystems.
                                </p>
                            </div>

                            <div className="text-block-product">
                                <h3>Produkte von Testsiegern</h3>
                                <p>
                                    Wir haben unser Produktportfolio nur mit
                                    Alarmanlagen von renommierten Herstellern
                                    ausgestattet. Alle von uns vertriebenen
                                    Alarmsysteme sind preisgekrönt und mehrfach
                                    von führenden Fachverbänden in der Kategorie
                                    „Professionelle Alarmanlagen“ als Testsieger
                                    ausgezeichnet worden.
                                </p>
                            </div>
                            <div className="text-block-product">
                                <h3>KfW-Förderfähigkeit</h3>
                                <p>
                                    Unsere Alarmanlagen entsprechen der Norm DIN
                                    EN 50131 (Grad 2) – somit erfüllen Sie die
                                    Voraussetzungen für eine Förderfähigkeit
                                    durch die KfW. Mit dieser Förderung können
                                    sie bei der Anschaffung Ihrer Alarmanlage
                                    für Haus oder Wohnung sparen.
                                </p>
                            </div>
                            <div className="text-block-product">
                                <h3>Wertsteigernde Investition</h3>
                                <p>
                                    Das Vorhandensein einer Alarmanlage steigert
                                    nachweislich die Attraktivität für
                                    potentielle Immobilienkäufer und somit auch
                                    den Verkehrswert von Haus oder Wohnung.
                                    Alarmtechnik unterstützt also nicht nur beim
                                    Einbruchschutz.
                                </p>
                            </div>
                            <div>
                                <div className="centerButtonInDiv">
                                    <Link
                                        className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                                        to="/preisrechner/"
                                        id="btn_ vorteilealarm_mehrerfahren"
                                    >
                                        Preis berechnen
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
