import React from "react";
import { Link } from "gatsby";
const TickBlue = "/img/homepage/icons8-done-30.png";
function ListItem(props) {
    return (
        <p style={{ marginTop: "12px" }}>
            <img
                src={TickBlue}
                style={{
                    width: "35px",
                    height: "35px",
                    verticalAlign: "middle",
                    display: "block",
                    float: "left",
                }}
            />
            <span
                style={{
                    position: "relative",
                    // top: "-7px",
                    verticalAlign: "middle",
                    left: "5px",

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    // webkitLineClamp: "3",
                    webkitBoxOrient: "vertical",
                }}
                className="font-pb3"
            >
                <b> {props.text}</b>
            </span>
        </p>
    );
}
export default class NewHomeWeSecure extends React.Component {
    render() {
        return (
            <div style={{ backgroundColor: "#F2F2F2" }}>
                <div
                    className="section how-to2"
                    style={{ marginTop: "40px", paddingBottom: "60px" }}
                >
                    <div className="body">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2 className="title">
                                    Höchste Qualität von ausgezeichneten
                                    Partnern.
                                </h2>
                                <h3>
                                    Wir vertreiben nur ausgewählte Produkte von
                                    renommierten Herstellern.
                                </h3>
                            </div>
                        </div>

                        <div>
                            <div className="columns">
                                <div className="column has-text-centered">
                                    {/* <Link to="/alarmanlagen"> */}
                                    <div
                                        className="card"
                                        style={{
                                            border: "4px solid darkgrey",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <div
                                            className="card-title"
                                            style={{
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                            }}
                                        >
                                            <img
                                                style={{ width: "10rem" }}
                                                src="/img/productpage/Ajax-logo.png"
                                                alt="Alarmgen"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "25px",
                                                textAlign: "initial",
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <ListItem text="Moderner Hersteller mit innovativsten Technologien " />
                                            <ListItem text="Modulares, kabelloses System" />
                                            <ListItem text="Leichte und intuitive Steuerung der Alarmanlage über App" />
                                            <ListItem text="Live-Bilder der Bewegungsmelder über das Smartphone ansehen" />
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                                <div className="column has-text-centered">
                                    {/* <Link to="/videoueberwachung/"> */}
                                    <div
                                        className="card"
                                        style={{
                                            border: "4px solid darkgrey",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <div
                                            className="card-title"
                                            style={{
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: "12rem",
                                                }}
                                                src="/img/productpage/Jablotron_Logo.png"
                                                alt=""
                                            />
                                        </div>

                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "25px",
                                                textAlign: "initial",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <ListItem text="Seit über 30 Jahren erfolgreich am Markt" />
                                            <ListItem text="Modulares Hybrid-System (kabellos oder verkabelt)" />
                                            <ListItem text="Intuitive Steuerung sowohl über Bedienteil, als auch App oder Smart Watch" />
                                            <ListItem text="Intelligente Funktionen: z.B. Licht ausschalten und geöffnete Fenster anzeigen" />
                                        </div>
                                    </div>
                                    {/* </Link>{" "} */}
                                </div>
                                <div className="column has-text-centered">
                                    {/* <Link to="/schliessanlagen/"> */}
                                    <div
                                        className="card"
                                        style={{
                                            border: "4px solid darkgrey",
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <div
                                            className="card-title"
                                            style={{
                                                paddingTop: "18px",
                                                // paddingBottom: "5px",
                                                // fontSize: "24px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: "8rem",
                                                }}
                                                src="/img/productpage/Daitem_Logo_533px_RGB_blue.png"
                                                alt="Wir installieren für sie"
                                            />
                                            {/* <div>
                                            <small
                                                style={{
                                                    fontSize: "17px",
                                                    color: "black",
                                                }}
                                            >
                                                Für alle Privatkunden
                                            </small>
                                        </div> */}
                                        </div>
                                        <div
                                            style={{
                                                // marginTop: "10px",
                                                marginBottom: "25px",
                                                textAlign: "initial",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <ListItem text="Ausgezeichneter deutsch-französischer Hersteller" />
                                            <ListItem text="Modulares, kabelloses System" />
                                            <ListItem text="Steuerung der Alarmanlage über Bedienteil mit Sprachausgabe" />
                                            <ListItem text="Funktion der aktiven Abschreckung, z.B. durch Sprachausgabe" />
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
